import { UseCase } from '@core/referential/use-case.enum'
import { createFileRoute, redirect, useRouter } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import PendingComponent from '@/components/pending-component'
import { Button } from '@/components/ui/button'
import { Permission } from '@/lib/permissions'
import { isUseCase } from '@/lib/utils'
import { mySitesQuery } from '@/queries/my-sites.query'

export const SearchSchema = z.object({
  tab: z.union([z.literal('map'), z.literal('list')]).optional(),
})

type Search = z.infer<typeof SearchSchema>

export const Route = createFileRoute('/_dashboard/my-sites/')({
  validateSearch(search: Search) {
    if (isUseCase(UseCase.ENERGY)) {
      return SearchSchema.parse({ tab: search.tab ?? 'map' })
    }

    return SearchSchema.parse({})
  },
  beforeLoad({ context }) {
    if (!context.user?.hasPermission(Permission.PATRIMONY_SITES)) {
      throw redirect({
        to: '/403',
      })
    }
  },
  loader({ context }) {
    return context.queryClient.ensureQueryData(mySitesQuery)
  },
  pendingComponent: PendingComponent,
  errorComponent: ErrorComponent,
})

function ErrorComponent() {
  const router = useRouter()
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-start gap-2">
      <p>{t('mySites.mySitesError')}.</p>
      <Button
        disabled={router.state.isLoading}
        onClick={async () => {
          await router.invalidate()
        }}
      >
        {t('common.button.retry')}
      </Button>
    </div>
  )
}

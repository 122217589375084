import { DeviceFamily } from '@core/referential/device-family.enum'
import { DeviceSubType, energyMeterMainSubTypes, energyMeterSubSubTypes } from '@core/referential/device-sub-type.enum'
import { UnitType } from '@core/referential/unit-type.enum'
import { UsageType } from '@core/referential/usage-type.enum'

import { type Device } from '@/queries/company.devices.query'

export function filterDevices({ devices, meterType, usageType }: { devices: Array<Device>; meterType: 'meter' | 'sub-meter'; usageType?: UsageType }) {
  return devices.filter(({ metadata }) => {
    if (meterType === 'meter') {
      if (usageType === UsageType.WATER) {
        return metadata.subType === DeviceSubType.WATER_METER_MAIN
      }

      return energyMeterMainSubTypes.includes(metadata.subType)
    }

    if (meterType === 'sub-meter') {
      if (usageType === UsageType.WATER) {
        return metadata.subType === DeviceSubType.WATER_METER_SUB
      }

      return energyMeterSubSubTypes.includes(metadata.subType)
    }

    return false
  })
}

const allowedDeviceFamilies = [DeviceFamily.ELECTRICITY, DeviceFamily.GAS, DeviceFamily.WATER, DeviceFamily.THERMAL] as const

type AllowedDeviceFamilies = (typeof allowedDeviceFamilies)[number]

export function filterDeviceFamilies({ devices, usageType }: { devices: Array<Device>; usageType?: UsageType }) {
  let deviceFamiliesByUsageType: Array<DeviceFamily> = []

  const availableFamilies = [...new Set(devices.flatMap((device) => device.metadata.deviceFamily))]

  switch (usageType) {
    case UsageType.ENERGY: {
      deviceFamiliesByUsageType = availableFamilies.filter((deviceFamily) => deviceFamily !== DeviceFamily.WATER)

      break
    }

    case UsageType.WATER: {
      deviceFamiliesByUsageType = availableFamilies.filter((deviceFamily) => deviceFamily === DeviceFamily.WATER)
      break
    }

    default: {
      return []
    }
  }

  return allowedDeviceFamilies.reduce<Array<AllowedDeviceFamilies>>((acc, deviceFamily) => {
    if (deviceFamiliesByUsageType.includes(deviceFamily)) {
      acc.push(deviceFamily)
    }

    return acc
  }, [])
}

export function filterSites({ devices }: { devices: Array<Device> }) {
  return devices.reduce<Array<{ id: string; name: string }>>((acc, { siteId, siteName }) => {
    if (!acc.some((site) => site.id === siteId)) {
      acc.push({ id: siteId, name: siteName })
    }

    return acc
  }, [])
}

export function getAvailableUnits(energy?: DeviceFamily): Array<UnitType> {
  switch (energy) {
    case DeviceFamily.GAS:
    case DeviceFamily.FUEL: {
      return [UnitType.KWH, UnitType.MWH, UnitType.M3]
    }

    case DeviceFamily.WATER: {
      return [UnitType.M3]
    }

    default: {
      return [UnitType.KWH, UnitType.MWH]
    }
  }
}

export function formatValueByUnit({ value, unit }: { value?: number | null; unit: UnitType }): number | null | undefined {
  if (typeof value !== 'number') {
    return value
  }

  if (unit === UnitType.MWH) {
    return value / 1000
  }

  return value
}

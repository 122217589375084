import { UseCase } from '@core/referential/use-case.enum'
import { createFileRoute, redirect } from '@tanstack/react-router'

import { SearchSchema as EnergyDashboardSearchSchema } from './_dashboard/energy-usage/dashboard'
import { SearchSchema as ActionsDoneSearchSchema } from './_dashboard/site/$id/actions-done'
import { SearchSchema as MonitoringSearchSchema } from './_dashboard/site/$id/monitoring'

import { useAuth } from '@/hooks/use-auth'
import { isUseCase } from '@/lib/utils'
import { mySitesQuery } from '@/queries/my-sites.query'

export const Route = createFileRoute('/_auth/')({
  async beforeLoad({ location, context }) {
    const { user } = useAuth.getState()

    if (user) {
      const mySites = await context.queryClient.fetchQuery(mySitesQuery)

      if (mySites.length === 0) {
        throw redirect({
          to: '/403',
          search: {
            reason: 'noSites',
          },
        })
      }

      const siteId = user.currentSiteId ?? mySites[0].id

      await useAuth.getState().setCurrentSiteId(siteId)

      if (isUseCase(UseCase.LEGIONELLA)) {
        throw redirect({
          to: '/site/$id/actions-done',
          params: { id: siteId },
          search: ActionsDoneSearchSchema.parse({}),
        })
      } else if (isUseCase(UseCase.COLD_STORAGE)) {
        throw redirect({
          to: '/site/$id/monitoring',
          params: { id: siteId },
          search: MonitoringSearchSchema.parse({}),
        })
      } else if (isUseCase(UseCase.ENERGY)) {
        throw redirect({
          to: '/energy-usage/dashboard',
          search: EnergyDashboardSearchSchema.parse({}),
        })
      } else {
        throw redirect({
          to: '/403',
          search: { reason: 'wrongUseCase' },
        })
      }
    }

    if (location.pathname === '/') {
      throw redirect({
        to: '/login',
        replace: true,
      })
    }
  },
})

/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as DashboardImport } from './routes/_dashboard'
import { Route as AuthImport } from './routes/_auth'
import { Route as AuthIndexImport } from './routes/_auth.index'
import { Route as R403IndexImport } from './routes/403.index'
import { Route as AuthResetPasswordImport } from './routes/_auth.reset-password'
import { Route as AuthLoginImport } from './routes/_auth.login'
import { Route as DashboardUsersIndexImport } from './routes/_dashboard/users/index'
import { Route as DashboardMySitesIndexImport } from './routes/_dashboard/my-sites/index'
import { Route as DashboardMyActionsIndexImport } from './routes/_dashboard/my-actions/index'
import { Route as DashboardEnergyUsageIndexImport } from './routes/_dashboard/energy-usage/index'
import { Route as DashboardSiteIdImport } from './routes/_dashboard.site.$id'
import { Route as DashboardUsersCreateIndexImport } from './routes/_dashboard/users/create/index'
import { Route as DashboardEnergyUsageSiteAnalysisIndexImport } from './routes/_dashboard/energy-usage/site-analysis/index'
import { Route as DashboardEnergyUsageMeterAnalysisIndexImport } from './routes/_dashboard/energy-usage/meter-analysis/index'
import { Route as DashboardEnergyUsageDashboardIndexImport } from './routes/_dashboard/energy-usage/dashboard/index'
import { Route as DashboardUsersEditUserIdIndexImport } from './routes/_dashboard/users/edit/$userId/index'
import { Route as DashboardSiteIdReportsIndexImport } from './routes/_dashboard/site/$id/reports/index'
import { Route as DashboardSiteIdNonConformityIndexImport } from './routes/_dashboard/site/$id/non-conformity/index'
import { Route as DashboardSiteIdMyActionsIndexImport } from './routes/_dashboard/site/$id/my-actions/index'
import { Route as DashboardSiteIdMonitoringIndexImport } from './routes/_dashboard/site/$id/monitoring/index'
import { Route as DashboardSiteIdInformationIndexImport } from './routes/_dashboard/site/$id/information/index'
import { Route as DashboardSiteIdEquipmentIndexImport } from './routes/_dashboard/site/$id/equipment/index'
import { Route as DashboardSiteIdDevicesIndexImport } from './routes/_dashboard/site/$id/devices/index'
import { Route as DashboardSiteIdAlertsIndexImport } from './routes/_dashboard/site/$id/alerts/index'
import { Route as DashboardSiteIdActionsDoneIndexImport } from './routes/_dashboard/site/$id/actions-done/index'
import { Route as DashboardSiteIdActionsAssignationIndexImport } from './routes/_dashboard/site/$id/actions-assignation/index'
import { Route as DashboardSiteIdNonConformityActionIdIndexImport } from './routes/_dashboard/site/$id/non-conformity/$actionId/index'
import { Route as DashboardSiteIdMyActionsActionIdIndexImport } from './routes/_dashboard/site/$id/my-actions/$actionId/index'
import { Route as DashboardSiteIdMonitoringDeviceIdIndexImport } from './routes/_dashboard/site/$id/monitoring/$deviceId/index'
import { Route as DashboardSiteIdInformationAddWorkIndexImport } from './routes/_dashboard/site/$id/information/add-work/index'
import { Route as DashboardSiteIdEquipmentEnergyIndexImport } from './routes/_dashboard/site/$id/equipment/energy/index'
import { Route as DashboardSiteIdEquipmentCreateIndexImport } from './routes/_dashboard/site/$id/equipment/create/index'
import { Route as DashboardSiteIdEquipmentChooseSampleIndexImport } from './routes/_dashboard/site/$id/equipment/choose-sample/index'
import { Route as DashboardSiteIdAlertsCreateIndexImport } from './routes/_dashboard/site/$id/alerts/create/index'
import { Route as DashboardSiteIdActionsDoneActionIdIndexImport } from './routes/_dashboard/site/$id/actions-done/$actionId/index'
import { Route as DashboardSiteIdEquipmentEquipmentIdLayoutImport } from './routes/_dashboard/site/$id/equipment/$equipmentId/_layout'
import { Route as DashboardSiteIdReportsConfigurationCreateIndexImport } from './routes/_dashboard/site/$id/reports/configuration/create/index'
import { Route as DashboardSiteIdInformationWorkWorkIdIndexImport } from './routes/_dashboard/site/$id/information/work/$workId/index'
import { Route as DashboardSiteIdEquipmentEnergyEquipmentIdIndexImport } from './routes/_dashboard/site/$id/equipment/energy/$equipmentId/index'
import { Route as DashboardSiteIdDevicesEditDeviceIdIndexImport } from './routes/_dashboard/site/$id/devices/edit/$deviceId/index'
import { Route as DashboardSiteIdAlertsEditAlertIdIndexImport } from './routes/_dashboard/site/$id/alerts/edit/$alertId/index'
import { Route as DashboardSiteIdEquipmentEquipmentIdLayoutInformationImport } from './routes/_dashboard/site/$id/equipment/$equipmentId/_layout/information'
import { Route as DashboardSiteIdEquipmentEquipmentIdLayoutDevicesImport } from './routes/_dashboard/site/$id/equipment/$equipmentId/_layout/devices'
import { Route as DashboardSiteIdEquipmentEquipmentIdLayoutActionsImport } from './routes/_dashboard/site/$id/equipment/$equipmentId/_layout/actions'
import { Route as DashboardSiteIdReportsConfigurationEditReportIdIndexImport } from './routes/_dashboard/site/$id/reports/configuration/edit/$reportId/index'
import { Route as DashboardSiteIdAlertsEditAlertIdCommentIndexImport } from './routes/_dashboard/site/$id/alerts/edit/$alertId/comment/index'

// Create Virtual Routes

const DashboardMySitesCreateIndexLazyImport = createFileRoute(
  '/_dashboard/my-sites/create/',
)()
const DashboardSiteIdEquipmentEquipmentIdImport = createFileRoute(
  '/_dashboard/site/$id/equipment/$equipmentId',
)()

// Create/Update Routes

const DashboardRoute = DashboardImport.update({
  id: '/_dashboard',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/_dashboard.lazy').then((d) => d.Route))

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const AuthIndexRoute = AuthIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthRoute,
} as any)

const R403IndexRoute = R403IndexImport.update({
  id: '/403/',
  path: '/403/',
  getParentRoute: () => rootRoute,
} as any)

const AuthResetPasswordRoute = AuthResetPasswordImport.update({
  id: '/reset-password',
  path: '/reset-password',
  getParentRoute: () => AuthRoute,
} as any)

const AuthLoginRoute = AuthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthRoute,
} as any)

const DashboardUsersIndexRoute = DashboardUsersIndexImport.update({
  id: '/users/',
  path: '/users/',
  getParentRoute: () => DashboardRoute,
} as any).lazy(() =>
  import('./routes/_dashboard/users/index.lazy').then((d) => d.Route),
)

const DashboardMySitesIndexRoute = DashboardMySitesIndexImport.update({
  id: '/my-sites/',
  path: '/my-sites/',
  getParentRoute: () => DashboardRoute,
} as any).lazy(() =>
  import('./routes/_dashboard/my-sites/index.lazy').then((d) => d.Route),
)

const DashboardMyActionsIndexRoute = DashboardMyActionsIndexImport.update({
  id: '/my-actions/',
  path: '/my-actions/',
  getParentRoute: () => DashboardRoute,
} as any).lazy(() =>
  import('./routes/_dashboard/my-actions/index.lazy').then((d) => d.Route),
)

const DashboardEnergyUsageIndexRoute = DashboardEnergyUsageIndexImport.update({
  id: '/energy-usage/',
  path: '/energy-usage/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardSiteIdRoute = DashboardSiteIdImport.update({
  id: '/site/$id',
  path: '/site/$id',
  getParentRoute: () => DashboardRoute,
} as any).lazy(() =>
  import('./routes/_dashboard.site.$id.lazy').then((d) => d.Route),
)

const DashboardMySitesCreateIndexLazyRoute =
  DashboardMySitesCreateIndexLazyImport.update({
    id: '/my-sites/create/',
    path: '/my-sites/create/',
    getParentRoute: () => DashboardRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/my-sites/create/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardUsersCreateIndexRoute = DashboardUsersCreateIndexImport.update({
  id: '/users/create/',
  path: '/users/create/',
  getParentRoute: () => DashboardRoute,
} as any).lazy(() =>
  import('./routes/_dashboard/users/create/index.lazy').then((d) => d.Route),
)

const DashboardEnergyUsageSiteAnalysisIndexRoute =
  DashboardEnergyUsageSiteAnalysisIndexImport.update({
    id: '/energy-usage/site-analysis/',
    path: '/energy-usage/site-analysis/',
    getParentRoute: () => DashboardRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/energy-usage/site-analysis/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardEnergyUsageMeterAnalysisIndexRoute =
  DashboardEnergyUsageMeterAnalysisIndexImport.update({
    id: '/energy-usage/meter-analysis/',
    path: '/energy-usage/meter-analysis/',
    getParentRoute: () => DashboardRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/energy-usage/meter-analysis/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardEnergyUsageDashboardIndexRoute =
  DashboardEnergyUsageDashboardIndexImport.update({
    id: '/energy-usage/dashboard/',
    path: '/energy-usage/dashboard/',
    getParentRoute: () => DashboardRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/energy-usage/dashboard/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardSiteIdEquipmentEquipmentIdRoute =
  DashboardSiteIdEquipmentEquipmentIdImport.update({
    id: '/equipment/$equipmentId',
    path: '/equipment/$equipmentId',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any)

const DashboardUsersEditUserIdIndexRoute =
  DashboardUsersEditUserIdIndexImport.update({
    id: '/users/edit/$userId/',
    path: '/users/edit/$userId/',
    getParentRoute: () => DashboardRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/users/edit/$userId/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardSiteIdReportsIndexRoute =
  DashboardSiteIdReportsIndexImport.update({
    id: '/reports/',
    path: '/reports/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/site/$id/reports/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardSiteIdNonConformityIndexRoute =
  DashboardSiteIdNonConformityIndexImport.update({
    id: '/non-conformity/',
    path: '/non-conformity/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/site/$id/non-conformity/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardSiteIdMyActionsIndexRoute =
  DashboardSiteIdMyActionsIndexImport.update({
    id: '/my-actions/',
    path: '/my-actions/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/site/$id/my-actions/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardSiteIdMonitoringIndexRoute =
  DashboardSiteIdMonitoringIndexImport.update({
    id: '/monitoring/',
    path: '/monitoring/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/site/$id/monitoring/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardSiteIdInformationIndexRoute =
  DashboardSiteIdInformationIndexImport.update({
    id: '/information/',
    path: '/information/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/site/$id/information/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardSiteIdEquipmentIndexRoute =
  DashboardSiteIdEquipmentIndexImport.update({
    id: '/equipment/',
    path: '/equipment/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/site/$id/equipment/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardSiteIdDevicesIndexRoute =
  DashboardSiteIdDevicesIndexImport.update({
    id: '/devices/',
    path: '/devices/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/site/$id/devices/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardSiteIdAlertsIndexRoute = DashboardSiteIdAlertsIndexImport.update(
  {
    id: '/alerts/',
    path: '/alerts/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any,
).lazy(() =>
  import('./routes/_dashboard/site/$id/alerts/index.lazy').then((d) => d.Route),
)

const DashboardSiteIdActionsDoneIndexRoute =
  DashboardSiteIdActionsDoneIndexImport.update({
    id: '/actions-done/',
    path: '/actions-done/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/site/$id/actions-done/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardSiteIdActionsAssignationIndexRoute =
  DashboardSiteIdActionsAssignationIndexImport.update({
    id: '/actions-assignation/',
    path: '/actions-assignation/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/site/$id/actions-assignation/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardSiteIdNonConformityActionIdIndexRoute =
  DashboardSiteIdNonConformityActionIdIndexImport.update({
    id: '/non-conformity/$actionId/',
    path: '/non-conformity/$actionId/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import(
      './routes/_dashboard/site/$id/non-conformity/$actionId/index.lazy'
    ).then((d) => d.Route),
  )

const DashboardSiteIdMyActionsActionIdIndexRoute =
  DashboardSiteIdMyActionsActionIdIndexImport.update({
    id: '/my-actions/$actionId/',
    path: '/my-actions/$actionId/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/site/$id/my-actions/$actionId/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardSiteIdMonitoringDeviceIdIndexRoute =
  DashboardSiteIdMonitoringDeviceIdIndexImport.update({
    id: '/monitoring/$deviceId/',
    path: '/monitoring/$deviceId/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/site/$id/monitoring/$deviceId/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardSiteIdInformationAddWorkIndexRoute =
  DashboardSiteIdInformationAddWorkIndexImport.update({
    id: '/information/add-work/',
    path: '/information/add-work/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/site/$id/information/add-work/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardSiteIdEquipmentEnergyIndexRoute =
  DashboardSiteIdEquipmentEnergyIndexImport.update({
    id: '/equipment/energy/',
    path: '/equipment/energy/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/site/$id/equipment/energy/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardSiteIdEquipmentCreateIndexRoute =
  DashboardSiteIdEquipmentCreateIndexImport.update({
    id: '/equipment/create/',
    path: '/equipment/create/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/site/$id/equipment/create/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardSiteIdEquipmentChooseSampleIndexRoute =
  DashboardSiteIdEquipmentChooseSampleIndexImport.update({
    id: '/equipment/choose-sample/',
    path: '/equipment/choose-sample/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import(
      './routes/_dashboard/site/$id/equipment/choose-sample/index.lazy'
    ).then((d) => d.Route),
  )

const DashboardSiteIdAlertsCreateIndexRoute =
  DashboardSiteIdAlertsCreateIndexImport.update({
    id: '/alerts/create/',
    path: '/alerts/create/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/site/$id/alerts/create/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardSiteIdActionsDoneActionIdIndexRoute =
  DashboardSiteIdActionsDoneActionIdIndexImport.update({
    id: '/actions-done/$actionId/',
    path: '/actions-done/$actionId/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import(
      './routes/_dashboard/site/$id/actions-done/$actionId/index.lazy'
    ).then((d) => d.Route),
  )

const DashboardSiteIdEquipmentEquipmentIdLayoutRoute =
  DashboardSiteIdEquipmentEquipmentIdLayoutImport.update({
    id: '/_layout',
    getParentRoute: () => DashboardSiteIdEquipmentEquipmentIdRoute,
  } as any)

const DashboardSiteIdReportsConfigurationCreateIndexRoute =
  DashboardSiteIdReportsConfigurationCreateIndexImport.update({
    id: '/reports/configuration/create/',
    path: '/reports/configuration/create/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import(
      './routes/_dashboard/site/$id/reports/configuration/create/index.lazy'
    ).then((d) => d.Route),
  )

const DashboardSiteIdInformationWorkWorkIdIndexRoute =
  DashboardSiteIdInformationWorkWorkIdIndexImport.update({
    id: '/information/work/$workId/',
    path: '/information/work/$workId/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import(
      './routes/_dashboard/site/$id/information/work/$workId/index.lazy'
    ).then((d) => d.Route),
  )

const DashboardSiteIdEquipmentEnergyEquipmentIdIndexRoute =
  DashboardSiteIdEquipmentEnergyEquipmentIdIndexImport.update({
    id: '/equipment/energy/$equipmentId/',
    path: '/equipment/energy/$equipmentId/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import(
      './routes/_dashboard/site/$id/equipment/energy/$equipmentId/index.lazy'
    ).then((d) => d.Route),
  )

const DashboardSiteIdDevicesEditDeviceIdIndexRoute =
  DashboardSiteIdDevicesEditDeviceIdIndexImport.update({
    id: '/devices/edit/$deviceId/',
    path: '/devices/edit/$deviceId/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import(
      './routes/_dashboard/site/$id/devices/edit/$deviceId/index.lazy'
    ).then((d) => d.Route),
  )

const DashboardSiteIdAlertsEditAlertIdIndexRoute =
  DashboardSiteIdAlertsEditAlertIdIndexImport.update({
    id: '/alerts/edit/$alertId/',
    path: '/alerts/edit/$alertId/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/site/$id/alerts/edit/$alertId/index.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardSiteIdEquipmentEquipmentIdLayoutInformationRoute =
  DashboardSiteIdEquipmentEquipmentIdLayoutInformationImport.update({
    id: '/information',
    path: '/information',
    getParentRoute: () => DashboardSiteIdEquipmentEquipmentIdLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_dashboard/site/$id/equipment/$equipmentId/_layout/information.lazy'
    ).then((d) => d.Route),
  )

const DashboardSiteIdEquipmentEquipmentIdLayoutDevicesRoute =
  DashboardSiteIdEquipmentEquipmentIdLayoutDevicesImport.update({
    id: '/devices',
    path: '/devices',
    getParentRoute: () => DashboardSiteIdEquipmentEquipmentIdLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_dashboard/site/$id/equipment/$equipmentId/_layout/devices.lazy'
    ).then((d) => d.Route),
  )

const DashboardSiteIdEquipmentEquipmentIdLayoutActionsRoute =
  DashboardSiteIdEquipmentEquipmentIdLayoutActionsImport.update({
    id: '/actions',
    path: '/actions',
    getParentRoute: () => DashboardSiteIdEquipmentEquipmentIdLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_dashboard/site/$id/equipment/$equipmentId/_layout/actions.lazy'
    ).then((d) => d.Route),
  )

const DashboardSiteIdReportsConfigurationEditReportIdIndexRoute =
  DashboardSiteIdReportsConfigurationEditReportIdIndexImport.update({
    id: '/reports/configuration/edit/$reportId/',
    path: '/reports/configuration/edit/$reportId/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import(
      './routes/_dashboard/site/$id/reports/configuration/edit/$reportId/index.lazy'
    ).then((d) => d.Route),
  )

const DashboardSiteIdAlertsEditAlertIdCommentIndexRoute =
  DashboardSiteIdAlertsEditAlertIdCommentIndexImport.update({
    id: '/alerts/edit/$alertId/comment/',
    path: '/alerts/edit/$alertId/comment/',
    getParentRoute: () => DashboardSiteIdRoute,
  } as any).lazy(() =>
    import(
      './routes/_dashboard/site/$id/alerts/edit/$alertId/comment/index.lazy'
    ).then((d) => d.Route),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_dashboard': {
      id: '/_dashboard'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    '/_auth/login': {
      id: '/_auth/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof AuthLoginImport
      parentRoute: typeof AuthImport
    }
    '/_auth/reset-password': {
      id: '/_auth/reset-password'
      path: '/reset-password'
      fullPath: '/reset-password'
      preLoaderRoute: typeof AuthResetPasswordImport
      parentRoute: typeof AuthImport
    }
    '/403/': {
      id: '/403/'
      path: '/403'
      fullPath: '/403'
      preLoaderRoute: typeof R403IndexImport
      parentRoute: typeof rootRoute
    }
    '/_auth/': {
      id: '/_auth/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthIndexImport
      parentRoute: typeof AuthImport
    }
    '/_dashboard/site/$id': {
      id: '/_dashboard/site/$id'
      path: '/site/$id'
      fullPath: '/site/$id'
      preLoaderRoute: typeof DashboardSiteIdImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/energy-usage/': {
      id: '/_dashboard/energy-usage/'
      path: '/energy-usage'
      fullPath: '/energy-usage'
      preLoaderRoute: typeof DashboardEnergyUsageIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/my-actions/': {
      id: '/_dashboard/my-actions/'
      path: '/my-actions'
      fullPath: '/my-actions'
      preLoaderRoute: typeof DashboardMyActionsIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/my-sites/': {
      id: '/_dashboard/my-sites/'
      path: '/my-sites'
      fullPath: '/my-sites'
      preLoaderRoute: typeof DashboardMySitesIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/users/': {
      id: '/_dashboard/users/'
      path: '/users'
      fullPath: '/users'
      preLoaderRoute: typeof DashboardUsersIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/energy-usage/dashboard/': {
      id: '/_dashboard/energy-usage/dashboard/'
      path: '/energy-usage/dashboard'
      fullPath: '/energy-usage/dashboard'
      preLoaderRoute: typeof DashboardEnergyUsageDashboardIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/energy-usage/meter-analysis/': {
      id: '/_dashboard/energy-usage/meter-analysis/'
      path: '/energy-usage/meter-analysis'
      fullPath: '/energy-usage/meter-analysis'
      preLoaderRoute: typeof DashboardEnergyUsageMeterAnalysisIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/energy-usage/site-analysis/': {
      id: '/_dashboard/energy-usage/site-analysis/'
      path: '/energy-usage/site-analysis'
      fullPath: '/energy-usage/site-analysis'
      preLoaderRoute: typeof DashboardEnergyUsageSiteAnalysisIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/users/create/': {
      id: '/_dashboard/users/create/'
      path: '/users/create'
      fullPath: '/users/create'
      preLoaderRoute: typeof DashboardUsersCreateIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/my-sites/create/': {
      id: '/_dashboard/my-sites/create/'
      path: '/my-sites/create'
      fullPath: '/my-sites/create'
      preLoaderRoute: typeof DashboardMySitesCreateIndexLazyImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/site/$id/actions-assignation/': {
      id: '/_dashboard/site/$id/actions-assignation/'
      path: '/actions-assignation'
      fullPath: '/site/$id/actions-assignation'
      preLoaderRoute: typeof DashboardSiteIdActionsAssignationIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/actions-done/': {
      id: '/_dashboard/site/$id/actions-done/'
      path: '/actions-done'
      fullPath: '/site/$id/actions-done'
      preLoaderRoute: typeof DashboardSiteIdActionsDoneIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/alerts/': {
      id: '/_dashboard/site/$id/alerts/'
      path: '/alerts'
      fullPath: '/site/$id/alerts'
      preLoaderRoute: typeof DashboardSiteIdAlertsIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/devices/': {
      id: '/_dashboard/site/$id/devices/'
      path: '/devices'
      fullPath: '/site/$id/devices'
      preLoaderRoute: typeof DashboardSiteIdDevicesIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/equipment/': {
      id: '/_dashboard/site/$id/equipment/'
      path: '/equipment'
      fullPath: '/site/$id/equipment'
      preLoaderRoute: typeof DashboardSiteIdEquipmentIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/information/': {
      id: '/_dashboard/site/$id/information/'
      path: '/information'
      fullPath: '/site/$id/information'
      preLoaderRoute: typeof DashboardSiteIdInformationIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/monitoring/': {
      id: '/_dashboard/site/$id/monitoring/'
      path: '/monitoring'
      fullPath: '/site/$id/monitoring'
      preLoaderRoute: typeof DashboardSiteIdMonitoringIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/my-actions/': {
      id: '/_dashboard/site/$id/my-actions/'
      path: '/my-actions'
      fullPath: '/site/$id/my-actions'
      preLoaderRoute: typeof DashboardSiteIdMyActionsIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/non-conformity/': {
      id: '/_dashboard/site/$id/non-conformity/'
      path: '/non-conformity'
      fullPath: '/site/$id/non-conformity'
      preLoaderRoute: typeof DashboardSiteIdNonConformityIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/reports/': {
      id: '/_dashboard/site/$id/reports/'
      path: '/reports'
      fullPath: '/site/$id/reports'
      preLoaderRoute: typeof DashboardSiteIdReportsIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/users/edit/$userId/': {
      id: '/_dashboard/users/edit/$userId/'
      path: '/users/edit/$userId'
      fullPath: '/users/edit/$userId'
      preLoaderRoute: typeof DashboardUsersEditUserIdIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/site/$id/equipment/$equipmentId': {
      id: '/_dashboard/site/$id/equipment/$equipmentId'
      path: '/equipment/$equipmentId'
      fullPath: '/site/$id/equipment/$equipmentId'
      preLoaderRoute: typeof DashboardSiteIdEquipmentEquipmentIdImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/equipment/$equipmentId/_layout': {
      id: '/_dashboard/site/$id/equipment/$equipmentId/_layout'
      path: '/equipment/$equipmentId'
      fullPath: '/site/$id/equipment/$equipmentId'
      preLoaderRoute: typeof DashboardSiteIdEquipmentEquipmentIdLayoutImport
      parentRoute: typeof DashboardSiteIdEquipmentEquipmentIdRoute
    }
    '/_dashboard/site/$id/actions-done/$actionId/': {
      id: '/_dashboard/site/$id/actions-done/$actionId/'
      path: '/actions-done/$actionId'
      fullPath: '/site/$id/actions-done/$actionId'
      preLoaderRoute: typeof DashboardSiteIdActionsDoneActionIdIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/alerts/create/': {
      id: '/_dashboard/site/$id/alerts/create/'
      path: '/alerts/create'
      fullPath: '/site/$id/alerts/create'
      preLoaderRoute: typeof DashboardSiteIdAlertsCreateIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/equipment/choose-sample/': {
      id: '/_dashboard/site/$id/equipment/choose-sample/'
      path: '/equipment/choose-sample'
      fullPath: '/site/$id/equipment/choose-sample'
      preLoaderRoute: typeof DashboardSiteIdEquipmentChooseSampleIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/equipment/create/': {
      id: '/_dashboard/site/$id/equipment/create/'
      path: '/equipment/create'
      fullPath: '/site/$id/equipment/create'
      preLoaderRoute: typeof DashboardSiteIdEquipmentCreateIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/equipment/energy/': {
      id: '/_dashboard/site/$id/equipment/energy/'
      path: '/equipment/energy'
      fullPath: '/site/$id/equipment/energy'
      preLoaderRoute: typeof DashboardSiteIdEquipmentEnergyIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/information/add-work/': {
      id: '/_dashboard/site/$id/information/add-work/'
      path: '/information/add-work'
      fullPath: '/site/$id/information/add-work'
      preLoaderRoute: typeof DashboardSiteIdInformationAddWorkIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/monitoring/$deviceId/': {
      id: '/_dashboard/site/$id/monitoring/$deviceId/'
      path: '/monitoring/$deviceId'
      fullPath: '/site/$id/monitoring/$deviceId'
      preLoaderRoute: typeof DashboardSiteIdMonitoringDeviceIdIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/my-actions/$actionId/': {
      id: '/_dashboard/site/$id/my-actions/$actionId/'
      path: '/my-actions/$actionId'
      fullPath: '/site/$id/my-actions/$actionId'
      preLoaderRoute: typeof DashboardSiteIdMyActionsActionIdIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/non-conformity/$actionId/': {
      id: '/_dashboard/site/$id/non-conformity/$actionId/'
      path: '/non-conformity/$actionId'
      fullPath: '/site/$id/non-conformity/$actionId'
      preLoaderRoute: typeof DashboardSiteIdNonConformityActionIdIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/equipment/$equipmentId/_layout/actions': {
      id: '/_dashboard/site/$id/equipment/$equipmentId/_layout/actions'
      path: '/actions'
      fullPath: '/site/$id/equipment/$equipmentId/actions'
      preLoaderRoute: typeof DashboardSiteIdEquipmentEquipmentIdLayoutActionsImport
      parentRoute: typeof DashboardSiteIdEquipmentEquipmentIdLayoutImport
    }
    '/_dashboard/site/$id/equipment/$equipmentId/_layout/devices': {
      id: '/_dashboard/site/$id/equipment/$equipmentId/_layout/devices'
      path: '/devices'
      fullPath: '/site/$id/equipment/$equipmentId/devices'
      preLoaderRoute: typeof DashboardSiteIdEquipmentEquipmentIdLayoutDevicesImport
      parentRoute: typeof DashboardSiteIdEquipmentEquipmentIdLayoutImport
    }
    '/_dashboard/site/$id/equipment/$equipmentId/_layout/information': {
      id: '/_dashboard/site/$id/equipment/$equipmentId/_layout/information'
      path: '/information'
      fullPath: '/site/$id/equipment/$equipmentId/information'
      preLoaderRoute: typeof DashboardSiteIdEquipmentEquipmentIdLayoutInformationImport
      parentRoute: typeof DashboardSiteIdEquipmentEquipmentIdLayoutImport
    }
    '/_dashboard/site/$id/alerts/edit/$alertId/': {
      id: '/_dashboard/site/$id/alerts/edit/$alertId/'
      path: '/alerts/edit/$alertId'
      fullPath: '/site/$id/alerts/edit/$alertId'
      preLoaderRoute: typeof DashboardSiteIdAlertsEditAlertIdIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/devices/edit/$deviceId/': {
      id: '/_dashboard/site/$id/devices/edit/$deviceId/'
      path: '/devices/edit/$deviceId'
      fullPath: '/site/$id/devices/edit/$deviceId'
      preLoaderRoute: typeof DashboardSiteIdDevicesEditDeviceIdIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/equipment/energy/$equipmentId/': {
      id: '/_dashboard/site/$id/equipment/energy/$equipmentId/'
      path: '/equipment/energy/$equipmentId'
      fullPath: '/site/$id/equipment/energy/$equipmentId'
      preLoaderRoute: typeof DashboardSiteIdEquipmentEnergyEquipmentIdIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/information/work/$workId/': {
      id: '/_dashboard/site/$id/information/work/$workId/'
      path: '/information/work/$workId'
      fullPath: '/site/$id/information/work/$workId'
      preLoaderRoute: typeof DashboardSiteIdInformationWorkWorkIdIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/reports/configuration/create/': {
      id: '/_dashboard/site/$id/reports/configuration/create/'
      path: '/reports/configuration/create'
      fullPath: '/site/$id/reports/configuration/create'
      preLoaderRoute: typeof DashboardSiteIdReportsConfigurationCreateIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/alerts/edit/$alertId/comment/': {
      id: '/_dashboard/site/$id/alerts/edit/$alertId/comment/'
      path: '/alerts/edit/$alertId/comment'
      fullPath: '/site/$id/alerts/edit/$alertId/comment'
      preLoaderRoute: typeof DashboardSiteIdAlertsEditAlertIdCommentIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
    '/_dashboard/site/$id/reports/configuration/edit/$reportId/': {
      id: '/_dashboard/site/$id/reports/configuration/edit/$reportId/'
      path: '/reports/configuration/edit/$reportId'
      fullPath: '/site/$id/reports/configuration/edit/$reportId'
      preLoaderRoute: typeof DashboardSiteIdReportsConfigurationEditReportIdIndexImport
      parentRoute: typeof DashboardSiteIdImport
    }
  }
}

// Create and export the route tree

interface AuthRouteChildren {
  AuthLoginRoute: typeof AuthLoginRoute
  AuthResetPasswordRoute: typeof AuthResetPasswordRoute
  AuthIndexRoute: typeof AuthIndexRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthLoginRoute: AuthLoginRoute,
  AuthResetPasswordRoute: AuthResetPasswordRoute,
  AuthIndexRoute: AuthIndexRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

interface DashboardSiteIdEquipmentEquipmentIdLayoutRouteChildren {
  DashboardSiteIdEquipmentEquipmentIdLayoutActionsRoute: typeof DashboardSiteIdEquipmentEquipmentIdLayoutActionsRoute
  DashboardSiteIdEquipmentEquipmentIdLayoutDevicesRoute: typeof DashboardSiteIdEquipmentEquipmentIdLayoutDevicesRoute
  DashboardSiteIdEquipmentEquipmentIdLayoutInformationRoute: typeof DashboardSiteIdEquipmentEquipmentIdLayoutInformationRoute
}

const DashboardSiteIdEquipmentEquipmentIdLayoutRouteChildren: DashboardSiteIdEquipmentEquipmentIdLayoutRouteChildren =
  {
    DashboardSiteIdEquipmentEquipmentIdLayoutActionsRoute:
      DashboardSiteIdEquipmentEquipmentIdLayoutActionsRoute,
    DashboardSiteIdEquipmentEquipmentIdLayoutDevicesRoute:
      DashboardSiteIdEquipmentEquipmentIdLayoutDevicesRoute,
    DashboardSiteIdEquipmentEquipmentIdLayoutInformationRoute:
      DashboardSiteIdEquipmentEquipmentIdLayoutInformationRoute,
  }

const DashboardSiteIdEquipmentEquipmentIdLayoutRouteWithChildren =
  DashboardSiteIdEquipmentEquipmentIdLayoutRoute._addFileChildren(
    DashboardSiteIdEquipmentEquipmentIdLayoutRouteChildren,
  )

interface DashboardSiteIdEquipmentEquipmentIdRouteChildren {
  DashboardSiteIdEquipmentEquipmentIdLayoutRoute: typeof DashboardSiteIdEquipmentEquipmentIdLayoutRouteWithChildren
}

const DashboardSiteIdEquipmentEquipmentIdRouteChildren: DashboardSiteIdEquipmentEquipmentIdRouteChildren =
  {
    DashboardSiteIdEquipmentEquipmentIdLayoutRoute:
      DashboardSiteIdEquipmentEquipmentIdLayoutRouteWithChildren,
  }

const DashboardSiteIdEquipmentEquipmentIdRouteWithChildren =
  DashboardSiteIdEquipmentEquipmentIdRoute._addFileChildren(
    DashboardSiteIdEquipmentEquipmentIdRouteChildren,
  )

interface DashboardSiteIdRouteChildren {
  DashboardSiteIdActionsAssignationIndexRoute: typeof DashboardSiteIdActionsAssignationIndexRoute
  DashboardSiteIdActionsDoneIndexRoute: typeof DashboardSiteIdActionsDoneIndexRoute
  DashboardSiteIdAlertsIndexRoute: typeof DashboardSiteIdAlertsIndexRoute
  DashboardSiteIdDevicesIndexRoute: typeof DashboardSiteIdDevicesIndexRoute
  DashboardSiteIdEquipmentIndexRoute: typeof DashboardSiteIdEquipmentIndexRoute
  DashboardSiteIdInformationIndexRoute: typeof DashboardSiteIdInformationIndexRoute
  DashboardSiteIdMonitoringIndexRoute: typeof DashboardSiteIdMonitoringIndexRoute
  DashboardSiteIdMyActionsIndexRoute: typeof DashboardSiteIdMyActionsIndexRoute
  DashboardSiteIdNonConformityIndexRoute: typeof DashboardSiteIdNonConformityIndexRoute
  DashboardSiteIdReportsIndexRoute: typeof DashboardSiteIdReportsIndexRoute
  DashboardSiteIdEquipmentEquipmentIdRoute: typeof DashboardSiteIdEquipmentEquipmentIdRouteWithChildren
  DashboardSiteIdActionsDoneActionIdIndexRoute: typeof DashboardSiteIdActionsDoneActionIdIndexRoute
  DashboardSiteIdAlertsCreateIndexRoute: typeof DashboardSiteIdAlertsCreateIndexRoute
  DashboardSiteIdEquipmentChooseSampleIndexRoute: typeof DashboardSiteIdEquipmentChooseSampleIndexRoute
  DashboardSiteIdEquipmentCreateIndexRoute: typeof DashboardSiteIdEquipmentCreateIndexRoute
  DashboardSiteIdEquipmentEnergyIndexRoute: typeof DashboardSiteIdEquipmentEnergyIndexRoute
  DashboardSiteIdInformationAddWorkIndexRoute: typeof DashboardSiteIdInformationAddWorkIndexRoute
  DashboardSiteIdMonitoringDeviceIdIndexRoute: typeof DashboardSiteIdMonitoringDeviceIdIndexRoute
  DashboardSiteIdMyActionsActionIdIndexRoute: typeof DashboardSiteIdMyActionsActionIdIndexRoute
  DashboardSiteIdNonConformityActionIdIndexRoute: typeof DashboardSiteIdNonConformityActionIdIndexRoute
  DashboardSiteIdAlertsEditAlertIdIndexRoute: typeof DashboardSiteIdAlertsEditAlertIdIndexRoute
  DashboardSiteIdDevicesEditDeviceIdIndexRoute: typeof DashboardSiteIdDevicesEditDeviceIdIndexRoute
  DashboardSiteIdEquipmentEnergyEquipmentIdIndexRoute: typeof DashboardSiteIdEquipmentEnergyEquipmentIdIndexRoute
  DashboardSiteIdInformationWorkWorkIdIndexRoute: typeof DashboardSiteIdInformationWorkWorkIdIndexRoute
  DashboardSiteIdReportsConfigurationCreateIndexRoute: typeof DashboardSiteIdReportsConfigurationCreateIndexRoute
  DashboardSiteIdAlertsEditAlertIdCommentIndexRoute: typeof DashboardSiteIdAlertsEditAlertIdCommentIndexRoute
  DashboardSiteIdReportsConfigurationEditReportIdIndexRoute: typeof DashboardSiteIdReportsConfigurationEditReportIdIndexRoute
}

const DashboardSiteIdRouteChildren: DashboardSiteIdRouteChildren = {
  DashboardSiteIdActionsAssignationIndexRoute:
    DashboardSiteIdActionsAssignationIndexRoute,
  DashboardSiteIdActionsDoneIndexRoute: DashboardSiteIdActionsDoneIndexRoute,
  DashboardSiteIdAlertsIndexRoute: DashboardSiteIdAlertsIndexRoute,
  DashboardSiteIdDevicesIndexRoute: DashboardSiteIdDevicesIndexRoute,
  DashboardSiteIdEquipmentIndexRoute: DashboardSiteIdEquipmentIndexRoute,
  DashboardSiteIdInformationIndexRoute: DashboardSiteIdInformationIndexRoute,
  DashboardSiteIdMonitoringIndexRoute: DashboardSiteIdMonitoringIndexRoute,
  DashboardSiteIdMyActionsIndexRoute: DashboardSiteIdMyActionsIndexRoute,
  DashboardSiteIdNonConformityIndexRoute:
    DashboardSiteIdNonConformityIndexRoute,
  DashboardSiteIdReportsIndexRoute: DashboardSiteIdReportsIndexRoute,
  DashboardSiteIdEquipmentEquipmentIdRoute:
    DashboardSiteIdEquipmentEquipmentIdRouteWithChildren,
  DashboardSiteIdActionsDoneActionIdIndexRoute:
    DashboardSiteIdActionsDoneActionIdIndexRoute,
  DashboardSiteIdAlertsCreateIndexRoute: DashboardSiteIdAlertsCreateIndexRoute,
  DashboardSiteIdEquipmentChooseSampleIndexRoute:
    DashboardSiteIdEquipmentChooseSampleIndexRoute,
  DashboardSiteIdEquipmentCreateIndexRoute:
    DashboardSiteIdEquipmentCreateIndexRoute,
  DashboardSiteIdEquipmentEnergyIndexRoute:
    DashboardSiteIdEquipmentEnergyIndexRoute,
  DashboardSiteIdInformationAddWorkIndexRoute:
    DashboardSiteIdInformationAddWorkIndexRoute,
  DashboardSiteIdMonitoringDeviceIdIndexRoute:
    DashboardSiteIdMonitoringDeviceIdIndexRoute,
  DashboardSiteIdMyActionsActionIdIndexRoute:
    DashboardSiteIdMyActionsActionIdIndexRoute,
  DashboardSiteIdNonConformityActionIdIndexRoute:
    DashboardSiteIdNonConformityActionIdIndexRoute,
  DashboardSiteIdAlertsEditAlertIdIndexRoute:
    DashboardSiteIdAlertsEditAlertIdIndexRoute,
  DashboardSiteIdDevicesEditDeviceIdIndexRoute:
    DashboardSiteIdDevicesEditDeviceIdIndexRoute,
  DashboardSiteIdEquipmentEnergyEquipmentIdIndexRoute:
    DashboardSiteIdEquipmentEnergyEquipmentIdIndexRoute,
  DashboardSiteIdInformationWorkWorkIdIndexRoute:
    DashboardSiteIdInformationWorkWorkIdIndexRoute,
  DashboardSiteIdReportsConfigurationCreateIndexRoute:
    DashboardSiteIdReportsConfigurationCreateIndexRoute,
  DashboardSiteIdAlertsEditAlertIdCommentIndexRoute:
    DashboardSiteIdAlertsEditAlertIdCommentIndexRoute,
  DashboardSiteIdReportsConfigurationEditReportIdIndexRoute:
    DashboardSiteIdReportsConfigurationEditReportIdIndexRoute,
}

const DashboardSiteIdRouteWithChildren = DashboardSiteIdRoute._addFileChildren(
  DashboardSiteIdRouteChildren,
)

interface DashboardRouteChildren {
  DashboardSiteIdRoute: typeof DashboardSiteIdRouteWithChildren
  DashboardEnergyUsageIndexRoute: typeof DashboardEnergyUsageIndexRoute
  DashboardMyActionsIndexRoute: typeof DashboardMyActionsIndexRoute
  DashboardMySitesIndexRoute: typeof DashboardMySitesIndexRoute
  DashboardUsersIndexRoute: typeof DashboardUsersIndexRoute
  DashboardEnergyUsageDashboardIndexRoute: typeof DashboardEnergyUsageDashboardIndexRoute
  DashboardEnergyUsageMeterAnalysisIndexRoute: typeof DashboardEnergyUsageMeterAnalysisIndexRoute
  DashboardEnergyUsageSiteAnalysisIndexRoute: typeof DashboardEnergyUsageSiteAnalysisIndexRoute
  DashboardUsersCreateIndexRoute: typeof DashboardUsersCreateIndexRoute
  DashboardMySitesCreateIndexLazyRoute: typeof DashboardMySitesCreateIndexLazyRoute
  DashboardUsersEditUserIdIndexRoute: typeof DashboardUsersEditUserIdIndexRoute
}

const DashboardRouteChildren: DashboardRouteChildren = {
  DashboardSiteIdRoute: DashboardSiteIdRouteWithChildren,
  DashboardEnergyUsageIndexRoute: DashboardEnergyUsageIndexRoute,
  DashboardMyActionsIndexRoute: DashboardMyActionsIndexRoute,
  DashboardMySitesIndexRoute: DashboardMySitesIndexRoute,
  DashboardUsersIndexRoute: DashboardUsersIndexRoute,
  DashboardEnergyUsageDashboardIndexRoute:
    DashboardEnergyUsageDashboardIndexRoute,
  DashboardEnergyUsageMeterAnalysisIndexRoute:
    DashboardEnergyUsageMeterAnalysisIndexRoute,
  DashboardEnergyUsageSiteAnalysisIndexRoute:
    DashboardEnergyUsageSiteAnalysisIndexRoute,
  DashboardUsersCreateIndexRoute: DashboardUsersCreateIndexRoute,
  DashboardMySitesCreateIndexLazyRoute: DashboardMySitesCreateIndexLazyRoute,
  DashboardUsersEditUserIdIndexRoute: DashboardUsersEditUserIdIndexRoute,
}

const DashboardRouteWithChildren = DashboardRoute._addFileChildren(
  DashboardRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof DashboardRouteWithChildren
  '/login': typeof AuthLoginRoute
  '/reset-password': typeof AuthResetPasswordRoute
  '/403': typeof R403IndexRoute
  '/': typeof AuthIndexRoute
  '/site/$id': typeof DashboardSiteIdRouteWithChildren
  '/energy-usage': typeof DashboardEnergyUsageIndexRoute
  '/my-actions': typeof DashboardMyActionsIndexRoute
  '/my-sites': typeof DashboardMySitesIndexRoute
  '/users': typeof DashboardUsersIndexRoute
  '/energy-usage/dashboard': typeof DashboardEnergyUsageDashboardIndexRoute
  '/energy-usage/meter-analysis': typeof DashboardEnergyUsageMeterAnalysisIndexRoute
  '/energy-usage/site-analysis': typeof DashboardEnergyUsageSiteAnalysisIndexRoute
  '/users/create': typeof DashboardUsersCreateIndexRoute
  '/my-sites/create': typeof DashboardMySitesCreateIndexLazyRoute
  '/site/$id/actions-assignation': typeof DashboardSiteIdActionsAssignationIndexRoute
  '/site/$id/actions-done': typeof DashboardSiteIdActionsDoneIndexRoute
  '/site/$id/alerts': typeof DashboardSiteIdAlertsIndexRoute
  '/site/$id/devices': typeof DashboardSiteIdDevicesIndexRoute
  '/site/$id/equipment': typeof DashboardSiteIdEquipmentIndexRoute
  '/site/$id/information': typeof DashboardSiteIdInformationIndexRoute
  '/site/$id/monitoring': typeof DashboardSiteIdMonitoringIndexRoute
  '/site/$id/my-actions': typeof DashboardSiteIdMyActionsIndexRoute
  '/site/$id/non-conformity': typeof DashboardSiteIdNonConformityIndexRoute
  '/site/$id/reports': typeof DashboardSiteIdReportsIndexRoute
  '/users/edit/$userId': typeof DashboardUsersEditUserIdIndexRoute
  '/site/$id/equipment/$equipmentId': typeof DashboardSiteIdEquipmentEquipmentIdLayoutRouteWithChildren
  '/site/$id/actions-done/$actionId': typeof DashboardSiteIdActionsDoneActionIdIndexRoute
  '/site/$id/alerts/create': typeof DashboardSiteIdAlertsCreateIndexRoute
  '/site/$id/equipment/choose-sample': typeof DashboardSiteIdEquipmentChooseSampleIndexRoute
  '/site/$id/equipment/create': typeof DashboardSiteIdEquipmentCreateIndexRoute
  '/site/$id/equipment/energy': typeof DashboardSiteIdEquipmentEnergyIndexRoute
  '/site/$id/information/add-work': typeof DashboardSiteIdInformationAddWorkIndexRoute
  '/site/$id/monitoring/$deviceId': typeof DashboardSiteIdMonitoringDeviceIdIndexRoute
  '/site/$id/my-actions/$actionId': typeof DashboardSiteIdMyActionsActionIdIndexRoute
  '/site/$id/non-conformity/$actionId': typeof DashboardSiteIdNonConformityActionIdIndexRoute
  '/site/$id/equipment/$equipmentId/actions': typeof DashboardSiteIdEquipmentEquipmentIdLayoutActionsRoute
  '/site/$id/equipment/$equipmentId/devices': typeof DashboardSiteIdEquipmentEquipmentIdLayoutDevicesRoute
  '/site/$id/equipment/$equipmentId/information': typeof DashboardSiteIdEquipmentEquipmentIdLayoutInformationRoute
  '/site/$id/alerts/edit/$alertId': typeof DashboardSiteIdAlertsEditAlertIdIndexRoute
  '/site/$id/devices/edit/$deviceId': typeof DashboardSiteIdDevicesEditDeviceIdIndexRoute
  '/site/$id/equipment/energy/$equipmentId': typeof DashboardSiteIdEquipmentEnergyEquipmentIdIndexRoute
  '/site/$id/information/work/$workId': typeof DashboardSiteIdInformationWorkWorkIdIndexRoute
  '/site/$id/reports/configuration/create': typeof DashboardSiteIdReportsConfigurationCreateIndexRoute
  '/site/$id/alerts/edit/$alertId/comment': typeof DashboardSiteIdAlertsEditAlertIdCommentIndexRoute
  '/site/$id/reports/configuration/edit/$reportId': typeof DashboardSiteIdReportsConfigurationEditReportIdIndexRoute
}

export interface FileRoutesByTo {
  '': typeof DashboardRouteWithChildren
  '/login': typeof AuthLoginRoute
  '/reset-password': typeof AuthResetPasswordRoute
  '/403': typeof R403IndexRoute
  '/': typeof AuthIndexRoute
  '/site/$id': typeof DashboardSiteIdRouteWithChildren
  '/energy-usage': typeof DashboardEnergyUsageIndexRoute
  '/my-actions': typeof DashboardMyActionsIndexRoute
  '/my-sites': typeof DashboardMySitesIndexRoute
  '/users': typeof DashboardUsersIndexRoute
  '/energy-usage/dashboard': typeof DashboardEnergyUsageDashboardIndexRoute
  '/energy-usage/meter-analysis': typeof DashboardEnergyUsageMeterAnalysisIndexRoute
  '/energy-usage/site-analysis': typeof DashboardEnergyUsageSiteAnalysisIndexRoute
  '/users/create': typeof DashboardUsersCreateIndexRoute
  '/my-sites/create': typeof DashboardMySitesCreateIndexLazyRoute
  '/site/$id/actions-assignation': typeof DashboardSiteIdActionsAssignationIndexRoute
  '/site/$id/actions-done': typeof DashboardSiteIdActionsDoneIndexRoute
  '/site/$id/alerts': typeof DashboardSiteIdAlertsIndexRoute
  '/site/$id/devices': typeof DashboardSiteIdDevicesIndexRoute
  '/site/$id/equipment': typeof DashboardSiteIdEquipmentIndexRoute
  '/site/$id/information': typeof DashboardSiteIdInformationIndexRoute
  '/site/$id/monitoring': typeof DashboardSiteIdMonitoringIndexRoute
  '/site/$id/my-actions': typeof DashboardSiteIdMyActionsIndexRoute
  '/site/$id/non-conformity': typeof DashboardSiteIdNonConformityIndexRoute
  '/site/$id/reports': typeof DashboardSiteIdReportsIndexRoute
  '/users/edit/$userId': typeof DashboardUsersEditUserIdIndexRoute
  '/site/$id/equipment/$equipmentId': typeof DashboardSiteIdEquipmentEquipmentIdLayoutRouteWithChildren
  '/site/$id/actions-done/$actionId': typeof DashboardSiteIdActionsDoneActionIdIndexRoute
  '/site/$id/alerts/create': typeof DashboardSiteIdAlertsCreateIndexRoute
  '/site/$id/equipment/choose-sample': typeof DashboardSiteIdEquipmentChooseSampleIndexRoute
  '/site/$id/equipment/create': typeof DashboardSiteIdEquipmentCreateIndexRoute
  '/site/$id/equipment/energy': typeof DashboardSiteIdEquipmentEnergyIndexRoute
  '/site/$id/information/add-work': typeof DashboardSiteIdInformationAddWorkIndexRoute
  '/site/$id/monitoring/$deviceId': typeof DashboardSiteIdMonitoringDeviceIdIndexRoute
  '/site/$id/my-actions/$actionId': typeof DashboardSiteIdMyActionsActionIdIndexRoute
  '/site/$id/non-conformity/$actionId': typeof DashboardSiteIdNonConformityActionIdIndexRoute
  '/site/$id/equipment/$equipmentId/actions': typeof DashboardSiteIdEquipmentEquipmentIdLayoutActionsRoute
  '/site/$id/equipment/$equipmentId/devices': typeof DashboardSiteIdEquipmentEquipmentIdLayoutDevicesRoute
  '/site/$id/equipment/$equipmentId/information': typeof DashboardSiteIdEquipmentEquipmentIdLayoutInformationRoute
  '/site/$id/alerts/edit/$alertId': typeof DashboardSiteIdAlertsEditAlertIdIndexRoute
  '/site/$id/devices/edit/$deviceId': typeof DashboardSiteIdDevicesEditDeviceIdIndexRoute
  '/site/$id/equipment/energy/$equipmentId': typeof DashboardSiteIdEquipmentEnergyEquipmentIdIndexRoute
  '/site/$id/information/work/$workId': typeof DashboardSiteIdInformationWorkWorkIdIndexRoute
  '/site/$id/reports/configuration/create': typeof DashboardSiteIdReportsConfigurationCreateIndexRoute
  '/site/$id/alerts/edit/$alertId/comment': typeof DashboardSiteIdAlertsEditAlertIdCommentIndexRoute
  '/site/$id/reports/configuration/edit/$reportId': typeof DashboardSiteIdReportsConfigurationEditReportIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/_dashboard': typeof DashboardRouteWithChildren
  '/_auth/login': typeof AuthLoginRoute
  '/_auth/reset-password': typeof AuthResetPasswordRoute
  '/403/': typeof R403IndexRoute
  '/_auth/': typeof AuthIndexRoute
  '/_dashboard/site/$id': typeof DashboardSiteIdRouteWithChildren
  '/_dashboard/energy-usage/': typeof DashboardEnergyUsageIndexRoute
  '/_dashboard/my-actions/': typeof DashboardMyActionsIndexRoute
  '/_dashboard/my-sites/': typeof DashboardMySitesIndexRoute
  '/_dashboard/users/': typeof DashboardUsersIndexRoute
  '/_dashboard/energy-usage/dashboard/': typeof DashboardEnergyUsageDashboardIndexRoute
  '/_dashboard/energy-usage/meter-analysis/': typeof DashboardEnergyUsageMeterAnalysisIndexRoute
  '/_dashboard/energy-usage/site-analysis/': typeof DashboardEnergyUsageSiteAnalysisIndexRoute
  '/_dashboard/users/create/': typeof DashboardUsersCreateIndexRoute
  '/_dashboard/my-sites/create/': typeof DashboardMySitesCreateIndexLazyRoute
  '/_dashboard/site/$id/actions-assignation/': typeof DashboardSiteIdActionsAssignationIndexRoute
  '/_dashboard/site/$id/actions-done/': typeof DashboardSiteIdActionsDoneIndexRoute
  '/_dashboard/site/$id/alerts/': typeof DashboardSiteIdAlertsIndexRoute
  '/_dashboard/site/$id/devices/': typeof DashboardSiteIdDevicesIndexRoute
  '/_dashboard/site/$id/equipment/': typeof DashboardSiteIdEquipmentIndexRoute
  '/_dashboard/site/$id/information/': typeof DashboardSiteIdInformationIndexRoute
  '/_dashboard/site/$id/monitoring/': typeof DashboardSiteIdMonitoringIndexRoute
  '/_dashboard/site/$id/my-actions/': typeof DashboardSiteIdMyActionsIndexRoute
  '/_dashboard/site/$id/non-conformity/': typeof DashboardSiteIdNonConformityIndexRoute
  '/_dashboard/site/$id/reports/': typeof DashboardSiteIdReportsIndexRoute
  '/_dashboard/users/edit/$userId/': typeof DashboardUsersEditUserIdIndexRoute
  '/_dashboard/site/$id/equipment/$equipmentId': typeof DashboardSiteIdEquipmentEquipmentIdRouteWithChildren
  '/_dashboard/site/$id/equipment/$equipmentId/_layout': typeof DashboardSiteIdEquipmentEquipmentIdLayoutRouteWithChildren
  '/_dashboard/site/$id/actions-done/$actionId/': typeof DashboardSiteIdActionsDoneActionIdIndexRoute
  '/_dashboard/site/$id/alerts/create/': typeof DashboardSiteIdAlertsCreateIndexRoute
  '/_dashboard/site/$id/equipment/choose-sample/': typeof DashboardSiteIdEquipmentChooseSampleIndexRoute
  '/_dashboard/site/$id/equipment/create/': typeof DashboardSiteIdEquipmentCreateIndexRoute
  '/_dashboard/site/$id/equipment/energy/': typeof DashboardSiteIdEquipmentEnergyIndexRoute
  '/_dashboard/site/$id/information/add-work/': typeof DashboardSiteIdInformationAddWorkIndexRoute
  '/_dashboard/site/$id/monitoring/$deviceId/': typeof DashboardSiteIdMonitoringDeviceIdIndexRoute
  '/_dashboard/site/$id/my-actions/$actionId/': typeof DashboardSiteIdMyActionsActionIdIndexRoute
  '/_dashboard/site/$id/non-conformity/$actionId/': typeof DashboardSiteIdNonConformityActionIdIndexRoute
  '/_dashboard/site/$id/equipment/$equipmentId/_layout/actions': typeof DashboardSiteIdEquipmentEquipmentIdLayoutActionsRoute
  '/_dashboard/site/$id/equipment/$equipmentId/_layout/devices': typeof DashboardSiteIdEquipmentEquipmentIdLayoutDevicesRoute
  '/_dashboard/site/$id/equipment/$equipmentId/_layout/information': typeof DashboardSiteIdEquipmentEquipmentIdLayoutInformationRoute
  '/_dashboard/site/$id/alerts/edit/$alertId/': typeof DashboardSiteIdAlertsEditAlertIdIndexRoute
  '/_dashboard/site/$id/devices/edit/$deviceId/': typeof DashboardSiteIdDevicesEditDeviceIdIndexRoute
  '/_dashboard/site/$id/equipment/energy/$equipmentId/': typeof DashboardSiteIdEquipmentEnergyEquipmentIdIndexRoute
  '/_dashboard/site/$id/information/work/$workId/': typeof DashboardSiteIdInformationWorkWorkIdIndexRoute
  '/_dashboard/site/$id/reports/configuration/create/': typeof DashboardSiteIdReportsConfigurationCreateIndexRoute
  '/_dashboard/site/$id/alerts/edit/$alertId/comment/': typeof DashboardSiteIdAlertsEditAlertIdCommentIndexRoute
  '/_dashboard/site/$id/reports/configuration/edit/$reportId/': typeof DashboardSiteIdReportsConfigurationEditReportIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/login'
    | '/reset-password'
    | '/403'
    | '/'
    | '/site/$id'
    | '/energy-usage'
    | '/my-actions'
    | '/my-sites'
    | '/users'
    | '/energy-usage/dashboard'
    | '/energy-usage/meter-analysis'
    | '/energy-usage/site-analysis'
    | '/users/create'
    | '/my-sites/create'
    | '/site/$id/actions-assignation'
    | '/site/$id/actions-done'
    | '/site/$id/alerts'
    | '/site/$id/devices'
    | '/site/$id/equipment'
    | '/site/$id/information'
    | '/site/$id/monitoring'
    | '/site/$id/my-actions'
    | '/site/$id/non-conformity'
    | '/site/$id/reports'
    | '/users/edit/$userId'
    | '/site/$id/equipment/$equipmentId'
    | '/site/$id/actions-done/$actionId'
    | '/site/$id/alerts/create'
    | '/site/$id/equipment/choose-sample'
    | '/site/$id/equipment/create'
    | '/site/$id/equipment/energy'
    | '/site/$id/information/add-work'
    | '/site/$id/monitoring/$deviceId'
    | '/site/$id/my-actions/$actionId'
    | '/site/$id/non-conformity/$actionId'
    | '/site/$id/equipment/$equipmentId/actions'
    | '/site/$id/equipment/$equipmentId/devices'
    | '/site/$id/equipment/$equipmentId/information'
    | '/site/$id/alerts/edit/$alertId'
    | '/site/$id/devices/edit/$deviceId'
    | '/site/$id/equipment/energy/$equipmentId'
    | '/site/$id/information/work/$workId'
    | '/site/$id/reports/configuration/create'
    | '/site/$id/alerts/edit/$alertId/comment'
    | '/site/$id/reports/configuration/edit/$reportId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/login'
    | '/reset-password'
    | '/403'
    | '/'
    | '/site/$id'
    | '/energy-usage'
    | '/my-actions'
    | '/my-sites'
    | '/users'
    | '/energy-usage/dashboard'
    | '/energy-usage/meter-analysis'
    | '/energy-usage/site-analysis'
    | '/users/create'
    | '/my-sites/create'
    | '/site/$id/actions-assignation'
    | '/site/$id/actions-done'
    | '/site/$id/alerts'
    | '/site/$id/devices'
    | '/site/$id/equipment'
    | '/site/$id/information'
    | '/site/$id/monitoring'
    | '/site/$id/my-actions'
    | '/site/$id/non-conformity'
    | '/site/$id/reports'
    | '/users/edit/$userId'
    | '/site/$id/equipment/$equipmentId'
    | '/site/$id/actions-done/$actionId'
    | '/site/$id/alerts/create'
    | '/site/$id/equipment/choose-sample'
    | '/site/$id/equipment/create'
    | '/site/$id/equipment/energy'
    | '/site/$id/information/add-work'
    | '/site/$id/monitoring/$deviceId'
    | '/site/$id/my-actions/$actionId'
    | '/site/$id/non-conformity/$actionId'
    | '/site/$id/equipment/$equipmentId/actions'
    | '/site/$id/equipment/$equipmentId/devices'
    | '/site/$id/equipment/$equipmentId/information'
    | '/site/$id/alerts/edit/$alertId'
    | '/site/$id/devices/edit/$deviceId'
    | '/site/$id/equipment/energy/$equipmentId'
    | '/site/$id/information/work/$workId'
    | '/site/$id/reports/configuration/create'
    | '/site/$id/alerts/edit/$alertId/comment'
    | '/site/$id/reports/configuration/edit/$reportId'
  id:
    | '__root__'
    | '/_auth'
    | '/_dashboard'
    | '/_auth/login'
    | '/_auth/reset-password'
    | '/403/'
    | '/_auth/'
    | '/_dashboard/site/$id'
    | '/_dashboard/energy-usage/'
    | '/_dashboard/my-actions/'
    | '/_dashboard/my-sites/'
    | '/_dashboard/users/'
    | '/_dashboard/energy-usage/dashboard/'
    | '/_dashboard/energy-usage/meter-analysis/'
    | '/_dashboard/energy-usage/site-analysis/'
    | '/_dashboard/users/create/'
    | '/_dashboard/my-sites/create/'
    | '/_dashboard/site/$id/actions-assignation/'
    | '/_dashboard/site/$id/actions-done/'
    | '/_dashboard/site/$id/alerts/'
    | '/_dashboard/site/$id/devices/'
    | '/_dashboard/site/$id/equipment/'
    | '/_dashboard/site/$id/information/'
    | '/_dashboard/site/$id/monitoring/'
    | '/_dashboard/site/$id/my-actions/'
    | '/_dashboard/site/$id/non-conformity/'
    | '/_dashboard/site/$id/reports/'
    | '/_dashboard/users/edit/$userId/'
    | '/_dashboard/site/$id/equipment/$equipmentId'
    | '/_dashboard/site/$id/equipment/$equipmentId/_layout'
    | '/_dashboard/site/$id/actions-done/$actionId/'
    | '/_dashboard/site/$id/alerts/create/'
    | '/_dashboard/site/$id/equipment/choose-sample/'
    | '/_dashboard/site/$id/equipment/create/'
    | '/_dashboard/site/$id/equipment/energy/'
    | '/_dashboard/site/$id/information/add-work/'
    | '/_dashboard/site/$id/monitoring/$deviceId/'
    | '/_dashboard/site/$id/my-actions/$actionId/'
    | '/_dashboard/site/$id/non-conformity/$actionId/'
    | '/_dashboard/site/$id/equipment/$equipmentId/_layout/actions'
    | '/_dashboard/site/$id/equipment/$equipmentId/_layout/devices'
    | '/_dashboard/site/$id/equipment/$equipmentId/_layout/information'
    | '/_dashboard/site/$id/alerts/edit/$alertId/'
    | '/_dashboard/site/$id/devices/edit/$deviceId/'
    | '/_dashboard/site/$id/equipment/energy/$equipmentId/'
    | '/_dashboard/site/$id/information/work/$workId/'
    | '/_dashboard/site/$id/reports/configuration/create/'
    | '/_dashboard/site/$id/alerts/edit/$alertId/comment/'
    | '/_dashboard/site/$id/reports/configuration/edit/$reportId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
  DashboardRoute: typeof DashboardRouteWithChildren
  R403IndexRoute: typeof R403IndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
  DashboardRoute: DashboardRouteWithChildren,
  R403IndexRoute: R403IndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/_dashboard",
        "/403/"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/login",
        "/_auth/reset-password",
        "/_auth/"
      ]
    },
    "/_dashboard": {
      "filePath": "_dashboard.tsx",
      "children": [
        "/_dashboard/site/$id",
        "/_dashboard/energy-usage/",
        "/_dashboard/my-actions/",
        "/_dashboard/my-sites/",
        "/_dashboard/users/",
        "/_dashboard/energy-usage/dashboard/",
        "/_dashboard/energy-usage/meter-analysis/",
        "/_dashboard/energy-usage/site-analysis/",
        "/_dashboard/users/create/",
        "/_dashboard/my-sites/create/",
        "/_dashboard/users/edit/$userId/"
      ]
    },
    "/_auth/login": {
      "filePath": "_auth.login.tsx",
      "parent": "/_auth"
    },
    "/_auth/reset-password": {
      "filePath": "_auth.reset-password.tsx",
      "parent": "/_auth"
    },
    "/403/": {
      "filePath": "403.index.tsx"
    },
    "/_auth/": {
      "filePath": "_auth.index.tsx",
      "parent": "/_auth"
    },
    "/_dashboard/site/$id": {
      "filePath": "_dashboard.site.$id.tsx",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/site/$id/actions-assignation/",
        "/_dashboard/site/$id/actions-done/",
        "/_dashboard/site/$id/alerts/",
        "/_dashboard/site/$id/devices/",
        "/_dashboard/site/$id/equipment/",
        "/_dashboard/site/$id/information/",
        "/_dashboard/site/$id/monitoring/",
        "/_dashboard/site/$id/my-actions/",
        "/_dashboard/site/$id/non-conformity/",
        "/_dashboard/site/$id/reports/",
        "/_dashboard/site/$id/equipment/$equipmentId",
        "/_dashboard/site/$id/actions-done/$actionId/",
        "/_dashboard/site/$id/alerts/create/",
        "/_dashboard/site/$id/equipment/choose-sample/",
        "/_dashboard/site/$id/equipment/create/",
        "/_dashboard/site/$id/equipment/energy/",
        "/_dashboard/site/$id/information/add-work/",
        "/_dashboard/site/$id/monitoring/$deviceId/",
        "/_dashboard/site/$id/my-actions/$actionId/",
        "/_dashboard/site/$id/non-conformity/$actionId/",
        "/_dashboard/site/$id/alerts/edit/$alertId/",
        "/_dashboard/site/$id/devices/edit/$deviceId/",
        "/_dashboard/site/$id/equipment/energy/$equipmentId/",
        "/_dashboard/site/$id/information/work/$workId/",
        "/_dashboard/site/$id/reports/configuration/create/",
        "/_dashboard/site/$id/alerts/edit/$alertId/comment/",
        "/_dashboard/site/$id/reports/configuration/edit/$reportId/"
      ]
    },
    "/_dashboard/energy-usage/": {
      "filePath": "_dashboard/energy-usage/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/my-actions/": {
      "filePath": "_dashboard/my-actions/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/my-sites/": {
      "filePath": "_dashboard/my-sites/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/users/": {
      "filePath": "_dashboard/users/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/energy-usage/dashboard/": {
      "filePath": "_dashboard/energy-usage/dashboard/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/energy-usage/meter-analysis/": {
      "filePath": "_dashboard/energy-usage/meter-analysis/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/energy-usage/site-analysis/": {
      "filePath": "_dashboard/energy-usage/site-analysis/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/users/create/": {
      "filePath": "_dashboard/users/create/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/my-sites/create/": {
      "filePath": "_dashboard/my-sites/create/index.lazy.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/site/$id/actions-assignation/": {
      "filePath": "_dashboard/site/$id/actions-assignation/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/actions-done/": {
      "filePath": "_dashboard/site/$id/actions-done/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/alerts/": {
      "filePath": "_dashboard/site/$id/alerts/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/devices/": {
      "filePath": "_dashboard/site/$id/devices/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/equipment/": {
      "filePath": "_dashboard/site/$id/equipment/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/information/": {
      "filePath": "_dashboard/site/$id/information/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/monitoring/": {
      "filePath": "_dashboard/site/$id/monitoring/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/my-actions/": {
      "filePath": "_dashboard/site/$id/my-actions/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/non-conformity/": {
      "filePath": "_dashboard/site/$id/non-conformity/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/reports/": {
      "filePath": "_dashboard/site/$id/reports/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/users/edit/$userId/": {
      "filePath": "_dashboard/users/edit/$userId/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/site/$id/equipment/$equipmentId": {
      "filePath": "_dashboard/site/$id/equipment/$equipmentId",
      "parent": "/_dashboard/site/$id",
      "children": [
        "/_dashboard/site/$id/equipment/$equipmentId/_layout"
      ]
    },
    "/_dashboard/site/$id/equipment/$equipmentId/_layout": {
      "filePath": "_dashboard/site/$id/equipment/$equipmentId/_layout.tsx",
      "parent": "/_dashboard/site/$id/equipment/$equipmentId",
      "children": [
        "/_dashboard/site/$id/equipment/$equipmentId/_layout/actions",
        "/_dashboard/site/$id/equipment/$equipmentId/_layout/devices",
        "/_dashboard/site/$id/equipment/$equipmentId/_layout/information"
      ]
    },
    "/_dashboard/site/$id/actions-done/$actionId/": {
      "filePath": "_dashboard/site/$id/actions-done/$actionId/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/alerts/create/": {
      "filePath": "_dashboard/site/$id/alerts/create/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/equipment/choose-sample/": {
      "filePath": "_dashboard/site/$id/equipment/choose-sample/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/equipment/create/": {
      "filePath": "_dashboard/site/$id/equipment/create/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/equipment/energy/": {
      "filePath": "_dashboard/site/$id/equipment/energy/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/information/add-work/": {
      "filePath": "_dashboard/site/$id/information/add-work/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/monitoring/$deviceId/": {
      "filePath": "_dashboard/site/$id/monitoring/$deviceId/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/my-actions/$actionId/": {
      "filePath": "_dashboard/site/$id/my-actions/$actionId/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/non-conformity/$actionId/": {
      "filePath": "_dashboard/site/$id/non-conformity/$actionId/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/equipment/$equipmentId/_layout/actions": {
      "filePath": "_dashboard/site/$id/equipment/$equipmentId/_layout/actions.tsx",
      "parent": "/_dashboard/site/$id/equipment/$equipmentId/_layout"
    },
    "/_dashboard/site/$id/equipment/$equipmentId/_layout/devices": {
      "filePath": "_dashboard/site/$id/equipment/$equipmentId/_layout/devices.tsx",
      "parent": "/_dashboard/site/$id/equipment/$equipmentId/_layout"
    },
    "/_dashboard/site/$id/equipment/$equipmentId/_layout/information": {
      "filePath": "_dashboard/site/$id/equipment/$equipmentId/_layout/information.tsx",
      "parent": "/_dashboard/site/$id/equipment/$equipmentId/_layout"
    },
    "/_dashboard/site/$id/alerts/edit/$alertId/": {
      "filePath": "_dashboard/site/$id/alerts/edit/$alertId/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/devices/edit/$deviceId/": {
      "filePath": "_dashboard/site/$id/devices/edit/$deviceId/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/equipment/energy/$equipmentId/": {
      "filePath": "_dashboard/site/$id/equipment/energy/$equipmentId/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/information/work/$workId/": {
      "filePath": "_dashboard/site/$id/information/work/$workId/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/reports/configuration/create/": {
      "filePath": "_dashboard/site/$id/reports/configuration/create/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/alerts/edit/$alertId/comment/": {
      "filePath": "_dashboard/site/$id/alerts/edit/$alertId/comment/index.tsx",
      "parent": "/_dashboard/site/$id"
    },
    "/_dashboard/site/$id/reports/configuration/edit/$reportId/": {
      "filePath": "_dashboard/site/$id/reports/configuration/edit/$reportId/index.tsx",
      "parent": "/_dashboard/site/$id"
    }
  }
}
ROUTE_MANIFEST_END */

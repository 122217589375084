import { GetByIdSiteSchemaPayload } from '@shared/schema/site.get-by-id.schema'
import { createFileRoute, notFound } from '@tanstack/react-router'
import { HTTPError } from 'ky'

import { useAuth } from '@/hooks/use-auth'
import { siteQuery } from '@/queries/site.query'

export const Route = createFileRoute('/_dashboard/site/$id')({
  parseParams(params) {
    return GetByIdSiteSchemaPayload.parse(params)
  },
  async beforeLoad({ params, cause, context, location }) {
    if (location.pathname.split('/').length === 3) {
      throw notFound()
    }

    // update current site id in the top-bar state (if you refresh the page for example)
    if ('id' in params && cause !== 'preload') {
      try {
        await context.queryClient.ensureQueryData(siteQuery(params.id))
      } catch (error) {
        if (error instanceof HTTPError && !error.response.ok) {
          // if the site is not found or there were an error, redirect to the 404 page
          throw notFound()
        }

        throw error
      }

      await useAuth.getState().setCurrentSiteId(params.id)
    }
  },
})

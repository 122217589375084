import { UseCase } from '@core/referential/use-case.enum'
import { createFileRoute, ErrorComponent, redirect } from '@tanstack/react-router'

import PendingComponent from '@/components/pending-component'
import { Permission } from '@/lib/permissions'
import { isUseCase } from '@/lib/utils'

export const Route = createFileRoute('/_dashboard/site/$id/equipment/energy/')({
  beforeLoad({ context }) {
    if (!context.user?.hasPermission(Permission.SITE_EQUIPMENT)) {
      throw redirect({
        to: '/403',
      })
    }

    if (!isUseCase(UseCase.ENERGY)) {
      throw redirect({
        to: '/403',
        search: { reason: 'wrongUseCase' },
      })
    }
  },
  pendingComponent: PendingComponent,
  errorComponent: ErrorComponent,
})

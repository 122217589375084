import { Frequency } from '@core/referential/frequency.enum'
import { UnitType } from '@core/referential/unit-type.enum'
import { UseCase } from '@core/referential/use-case.enum'
import { createFileRoute, redirect } from '@tanstack/react-router'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { z } from 'zod'

import PendingComponent from '@/components/pending-component'
import { createDefaultErrorComponent } from '@/create-default-error-component'
import { parseDateRange } from '@/lib/date'
import { isUseCase } from '@/lib/utils'

export const defaultDateRange = parseDateRange({
  startDate: dayjs().startOf('month').subtract(1, 'month').toDate(),
  endDate: dayjs().endOf('month').toDate(),
})

export const SearchSchema = z.object({
  startDate: z.string().default(defaultDateRange.startDate),
  endDate: z.string().default(defaultDateRange.endDate),
  frequency: z.nativeEnum(Frequency).default(Frequency.MONTHLY),
  sites: z.array(z.string()).optional(),
  unit: z.nativeEnum(UnitType).default(UnitType.KWH),
})

export type Search = z.infer<typeof SearchSchema>

export const Route = createFileRoute('/_dashboard/energy-usage/dashboard/')({
  validateSearch: SearchSchema,
  beforeLoad() {
    if (!isUseCase(UseCase.ENERGY)) {
      throw redirect({
        to: '/403',
        search: { reason: 'wrongUseCase' },
      })
    }
  },
  pendingComponent: PendingComponent,
  errorComponent: createDefaultErrorComponent(t('site.siteLoadingError')),
})

export enum DeviceSubType {
  BOILER = 'boiler',
  BUTTER_EGG_CHEESE = 'butterEggCheese',
  CARRIAGE_RETURN = 'carriageReturn',
  CARRIAGE_START = 'carriageStart',
  DATA_CENTER = 'dataCenter',
  DEFROSTING = 'defrosting',
  DOOR_MAIN = 'doorMain',
  DOOR_WC = 'doorWc',
  FINISHED_PRODUCTS = 'finishedProduct',
  FLOW_COLD = 'flowCold',
  FLOW_HOT = 'flowHot',
  FOOD = 'food',
  FRUIT_VEGETABLE = 'fruitVegetables',
  MEAT = 'meat',
  MEDICINE_AMBIENT = 'medicineAmbient',
  MEDICINE_COLD = 'medicineCold',
  MORGUE = 'morgue',
  NEED_CLEAN = 'needClean',
  NEED_PAPER = 'needPaper',
  NEGATIVE = 'negative',
  NONE = 'none',
  OTHER_COLD = 'otherCold',
  OTHER_HOT = 'otherHot',
  PACKAGING = 'packaging',
  PREPARATION = 'preparation',
  SATISFIED = 'satisfied',
  SUPPLY_COLD = 'supplyCold',
  SUPPLY_HOT = 'supplyHot',
  USAGE_BLENDED = 'usageBlended',
  USAGE_COLD = 'usageCold',
  USAGE_HOT = 'usageHot',
  WASTE = 'waste',
  AMBIENT = 'ambient',
  COLD_POSITIVE = 'coldPositive',
  COLD_NEGATIVE = 'coldNegative',
  WATER_METER_MAIN = 'waterMeterMain',
  WATER_METER_SUB = 'waterMeterSub',
  GAS_METER_MAIN = 'gasMeterMain',
  GAS_METER_SUB = 'gasMeterSub',
  ELECTRICITY_METER_MAIN = 'electricityMeterMain',
  ELECTRICITY_METER_SUB = 'elecricityMeterSub',
  THERMAL_METER_MAIN = 'thermalMeterMain',
  THERMAL_METER_SUB = 'thermalMeterSub',
  FUEL_METER_MAIN = 'fuelMeterMain',
  FUEL_METER_SUB = 'fuelMeterSub',
}

export const energyMeterMainSubTypes = [DeviceSubType.ELECTRICITY_METER_MAIN, DeviceSubType.GAS_METER_MAIN, DeviceSubType.THERMAL_METER_MAIN]

export const energyMeterSubSubTypes = [DeviceSubType.ELECTRICITY_METER_SUB, DeviceSubType.GAS_METER_SUB, DeviceSubType.THERMAL_METER_SUB]

import { Frequency } from '@core/referential/frequency.enum'
import dayjs from 'dayjs'
import { type DateRange } from 'react-day-picker'

export type DateRangeString = {
  startDate: string
  endDate: string
}

export const dateFormat = 'YYYY-MM-DD'

export function parseDateRange({ startDate, endDate }: { startDate: Date; endDate: Date }): { startDate: string; endDate: string } {
  return {
    startDate: dayjs(startDate).format(dateFormat),
    endDate: dayjs(endDate).format(dateFormat),
  }
}

export function parseDateRangeString({ startDate, endDate }: DateRangeString): { from: Date; to: Date } {
  return {
    from: dayjs(startDate).toDate(),
    to: dayjs(endDate).toDate(),
  }
}

export function parseDurationMinutes(durationMinutes: number): { value: number; unit: 'minutes' | 'hours' | 'days' } {
  const duration = dayjs.duration(durationMinutes, 'minutes')
  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()

  if (minutes === 0) {
    if (hours === 0) {
      if (days === 0) {
        return { value: 0, unit: 'minutes' }
      }

      return { value: days, unit: 'days' }
    }

    return { value: hours, unit: 'hours' }
  }

  return { value: durationMinutes, unit: 'minutes' }
}

export function getExtremes(startTime: Date, endTime: Date | null) {
  return [dayjs(startTime).subtract(1, 'h').valueOf(), endTime ? dayjs(endTime).valueOf() : dayjs().valueOf()]
}

export function getWeeklyDateRange({ from, to }: DateRange): { from: Date; to: Date } {
  const startDate = dayjs(from)
  const endDate = dayjs(to)

  let monday
  let sunday

  if (startDate.day() === 0) {
    monday = startDate.day(1).subtract(1, 'week')
  } else {
    monday = startDate.day(1)
  }

  if (from === to && startDate.day() === 0) {
    sunday = startDate
  } else if (endDate.day() === 0) {
    sunday = endDate
  } else {
    sunday = endDate.day(0).add(1, 'week')
  }

  return { from: monday.toDate(), to: sunday.toDate() }
}

export function getMonthlyDateRange({ from, to }: DateRange): { from: Date; to: Date } {
  const startDate = dayjs(from)
  const endDate = dayjs(to)

  const startOfMonth = startDate.startOf('month')
  const endOfMonth = endDate.endOf('month')

  return { from: startOfMonth.toDate(), to: endOfMonth.toDate() }
}

export function transformDateRange({ from, to }: { from: Date; to: Date }, frequency?: Frequency): { from: Date; to: Date } {
  switch (frequency) {
    case Frequency.WEEKLY: {
      return getWeeklyDateRange({ from, to })
    }

    case Frequency.MONTHLY: {
      return getMonthlyDateRange({ from, to })
    }

    case Frequency.YEARLY: {
      return { from: dayjs(from).startOf('year').toDate(), to: dayjs(to).endOf('year').toDate() }
    }

    default: {
      return { from, to }
    }
  }
}

import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRouter, RouterProvider } from '@tanstack/react-router'
import { fetchAuthSession } from 'aws-amplify/auth'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import 'dayjs/locale/fr'
import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'
import '@/i18n'
import '@/configure-amplify'

import { Toaster } from '@/components/ui/sonner'
import { useAuth } from '@/hooks/use-auth'
import { routeTree } from '@/route-tree'

// extend dayjs globally
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(tz)
dayjs.extend(duration)
dayjs.extend(localizedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.locale('fr')

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
})

export const Router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  defaultPreloadStaleTime: 0,
  context: {
    queryClient,
    user: undefined,
  },
})

Sentry.init({
  // eslint-disable-next-line no-undef
  enabled: process.env.NODE_ENV !== 'development',
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations() {
    const integrations = [Sentry.tanstackRouterBrowserTracingIntegration(Router), Sentry.httpClientIntegration()]

    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === 'production') {
      integrations.push(Sentry.replayIntegration())
    }

    return integrations
  },
  // eslint-disable-next-line no-undef
  environment: process.env.NODE_ENV,

  // Setting a sample rate is required for sending performance data.
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control.
  tracesSampleRate: 1,

  // Session Replay
  // eslint-disable-next-line no-undef
  replaysSessionSampleRate: process.env.NODE_ENV === 'development' ? 1 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const { fetchUser, logout } = useAuth.getState()

try {
  const user = await fetchUser()
  const currentSession = await fetchAuthSession()

  Sentry.setUser({
    email: user.email,
    username: user.name,
    companyId: user['custom:companyId'],
    companyName: user['custom:companyName'],
    useCases: user['custom:useCases'],
    role: user['custom:role'],
    accessToken: currentSession.tokens?.idToken?.toString(),
  })

  // cognito throw an error if the user is not authenticated, but we use fetchUser
  // to set the user in the cognito context
} catch {
  await logout()

  if (window.location.pathname !== '/login') {
    window.location.replace('/login')
  }
}

declare module '@tanstack/react-router' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Register {
    router: typeof Router
  }
}

function App() {
  const user = useAuth((state) => state.user)

  return <RouterProvider router={Router} context={{ user, queryClient }} />
}

const rootElement = document.querySelector('#root')

if (!rootElement) {
  throw new Error('No root element found')
}

if (!rootElement.innerHTML) {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <Toaster />
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </React.StrictMode>,
  )
}

import { GetDevicesResponseSchema } from '@shared/schema/device.get-all-by-company.schema'
import { queryOptions } from '@tanstack/react-query'

import { type QueryOptionsData } from '@/@types/query'
import { apiFetch } from '@/lib/api-fetch'

export function companyDevicesQuery() {
  return queryOptions({
    queryKey: ['company', 'devices'],
    async queryFn() {
      const rawResponse = await apiFetch.get(`device`)

      if (!rawResponse.ok) {
        throw new Error(`Failed to fetch company devices`)
      }

      try {
        return await GetDevicesResponseSchema.parseAsync(await rawResponse.json())
      } catch (error) {
        throw new Error('Failed to parse company devices response', { cause: error })
      }
    },
  })
}

export type Device = QueryOptionsData<typeof companyDevicesQuery>[number]
